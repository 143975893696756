import React from "react"
import ContactForm from "../../components/ContactForm"
import SEO from "../../components/SEO"
import { ContentHeading, ContentSubHeading } from "../../components/styles"

function SignUp() {
  return (
    <>
      <SEO title={"Sign Up for Beta | Pricelist"} />
      <div className="relative px-4 sm:px-6 lg:px-8">
        <div className="text-lg max-w-prose mx-auto">
          <h1>
            <ContentSubHeading>Sign Up</ContentSubHeading>
            <ContentHeading>
              Pricelist Beta access is opening soon
            </ContentHeading>
            <ContactForm
              cta="Get In Touch"
              description="Get in touch to request early access"
            />
          </h1>
        </div>
      </div>
    </>
  )
}

export default SignUp
