import React from "react";
import Layout from "../components/Layout";
import SignUp from "../views/SignUp/SignUp";

export default function SignUpForBeta() {
  return (
    <>
      <Layout>
        <SignUp />
      </Layout>
    </>
  );
}
